<template >
  <v-container>
    <v-btn color="error" x-large fab @click="colgar()">
      <v-icon>mdi-phone-hangup</v-icon>
    </v-btn>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :right="true"
      :timeout="3000"
      :color="snack_color"
    >
      {{ snack_text }}
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  name: "colgar",

  data: () => ({ snackbar: false, snack_color: "", snack_text: "" }),
  computed: {},
  methods: {
    colgar() {
      //this.$store.commit("SET_INICIARBOTONERA", false);
        //this.$store.commit("SET_VERCOLGAR", false);
      // this.$store.commit("SET_STATECRONO", "reset");
      this.$store.dispatch("setiniciarBotonera", false);
      this.$store.dispatch("setverColgar", false);
      this.$store.dispatch("setstateCrono", "reset");
      this.$store.dispatch("setllamadaEntranteVentanaTime", false);
      this.$store.getters.getinstanciaPhone.doHangup();
    },
    go(msg, color) {
      this.snack_text = msg;
      this.snack_color = color;
      this.snackbar = true;
    },
  },

  props: {
    itemEntrada: {},
  },
};
</script>

